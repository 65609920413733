import { useState } from "react";
import api from "../api";

export const useApi = (endpoint, method) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);

  const onRequest = async (body) => {
    setLoading(true);
    try {
      const res = await api[method](endpoint, body);
      setData(res);
      setLoading(false);
      return res;
    } catch (err) {
      typeof err?.response?.data?.message === "object" &&
        // eslint-disable-next-line array-callback-return
        err?.response?.data?.message?.map((msg) => {});
      typeof err?.response?.data?.message === "string" &&
        setError(err?.response?.data?.message || "something went wrong!");
      setLoading(false);
      return error;
    }
  };

  return { loading, error, data, onRequest };
};
