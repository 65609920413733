import React, { useEffect, useState } from "react";
import { Routes, Route, useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";

// STYLING
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.esm";
import "../../styles/normalize.scss";
// import styles from "./.module.scss";

// PAGES
import UserWalletPage from "../../pages/UserWalletPage";
import UserTransactionsPage from "../../pages/UserTransactionsPage";
import UserLevelPage from "../../pages/UserLevelPage";
import UserBadgesPage from "../../pages/UserBadgesPage";
import BadgeDetailsPage from "../../pages/BadgeDetailsPage";
import RankingsPage from "../../pages/RankingsPage";
import "../../config/i18n";
import { useTranslation } from "react-i18next";
import TermsAndConditions from "../../pages/TermsAndConditions";
import PrivacyPolicy from "../../pages/PrivacyPolicy";

export default function App() {
  const [TOKEN, setTOKEN] = useState(Cookies.get("TOKEN"));
  const [ready, setReady] = useState(false);
  const [params] = useSearchParams();
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(null);

  useEffect(() => {
    setLanguage(params.get("lang") || Cookies.get("lang") || "en");
  }, [params]);

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
      Cookies.set("lang", language, { expires: 20 });
      document.dir = language === "ar" ? "rtl" : "ltr";
      document.getElementsByTagName("html")[0].setAttribute("lang", language);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    (async () => {
      await setTOKEN(params.get("token"));
      Cookies.set("TOKEN", TOKEN);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  useEffect(() => {
    if (TOKEN) {
      setReady(true);
    }
  }, [TOKEN]);

  return (
    <Routes>
      {ready && (
        <>
          <Route path="/" element={<UserWalletPage />} />
          <Route
            path="/transactions"
            element={<UserTransactionsPage language={language} />}
          />
          <Route
            path="/level"
            element={<UserLevelPage language={language} />}
          />
          <Route
            path="/badge"
            element={<UserBadgesPage language={language} />}
          />
          <Route path="/badge-details/:id" element={<BadgeDetailsPage />} />
          <Route path="/rankings" element={<RankingsPage />} />
          <Route path="/terms&conditions" element={<TermsAndConditions />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        </>
      )}
    </Routes>
  );
}
