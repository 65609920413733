import React, { useEffect, useRef, useState } from "react";
import HTMLReactParser from "html-react-parser";
import { useApi } from "../../hooks/useApi";
import { BsFiletypePdf } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { jsPDF } from "jspdf";

export default function TermsAndConditions() {
  const codeRef = useRef(); // Create a reference for the code block

  const generatePdf = (data) => {
    const doc = new jsPDF();
    const codeContent = codeRef.current.innerText; // Get the code content

    // Add code content to the PDF, styling it as needed
    doc.setFont("courier", "normal");
    doc.setFontSize(10);
    doc.text(codeContent, 10, 10);
    // Save the generated PDF
    doc.save("terms-and-conditions.pdf");
  };

  const { t } = useTranslation();
  const [appInfo, setAppInfo] = useState(null);

  const { onRequest: getAppTerms } = useApi("app-info", "get", {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  useEffect(() => {
    (async () => {
      setAppInfo((await getAppTerms())?.data);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="container pt-5">
        {appInfo && (
          <div ref={codeRef}>
            {HTMLReactParser(appInfo?.data?.termsAndConditions)}
          </div>
        )}
      </div>
      <div
        style={{
          background: "#eee",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="container py-2 d-flex align-items-center justify-content-center">
          <button
            onClick={() => {
              generatePdf(appInfo?.data?.termsAndConditions);
            }}
            className="d-flex w-100 gap-3 align-items-center justify-content-center"
          >
            <span style={{ fontSize: "2rem", color: "red" }}>
              <BsFiletypePdf />
            </span>
            <span style={{ fontSize: ".8rem" }}>{t("download_pdf")}</span>
          </button>
        </div>
      </div>
    </>
  );
}
